<template>
    <div class="user-manager mt-5">
        <div class="d-flex justify-content-between">
            <h1>User Manager</h1>
            <span class="btn-add--top">
                        <b-btn v-if="isAdmin"
                               @click="navigate('/users/create')" variant="success">
                            <img src="../../assets/icons/person-fill.svg">
                            Add User
                            <b-icon-plus-circle-fill/>
                        </b-btn>
                    </span>
        </div>
        <data-table-local-sort ref="locTable"
                               :get-items="getItems"
                               :fields="fields"
                               :filter="filters"
                               v-model="filters"
                               :show-filters="true"
                               :show-pager="true"
                               :show-per-page="true">
            <!-- template for custom columns -->
            <template v-slot:customField="slotProps">
                <div v-if="slotProps.row.field.key === 'role'">
                    {{showRole(slotProps.row.item)}}
                </div>
                <div v-else-if="slotProps.row.field.key === 'actions'">
                    <b-btn class="btn btn-secondary btn-sm btn-icon mr-2"
                           v-if="slotProps.row.item.id !== $store.state.currentUser.uid"
                           @click="mask(slotProps.row.item)">
                        <b-icon-eyeglasses/>
                    </b-btn>
                    <router-link class="btn btn-secondary btn-sm btn-icon mr-2"
                                 :to="`/user/${slotProps.row.item.id}/edit`">
                        <b-icon-pencil/>
                    </router-link>
                    <b-button class="btn-icon"
                              variant="danger"
                              size="sm"
                              v-if="slotProps.row.item.id !== $store.state.currentUser.uid"
                              @click="deleteUser(slotProps.row.item)">
                        <b-icon-trash-fill/>
                    </b-button>
                </div>
            </template>
        </data-table-local-sort>
    </div>
</template>

<script>

import * as _ from "lodash";

export default {
    name: 'UserManager',
    data() {
        return {
            items: [],
            fields: [
                { key: 'email', sortable: true, label: 'Email' },
                { key: 'firstName', sortable: true, label: 'First' },
                { key: 'lastName', sortable: true, label: 'Last' },
                { key: 'role', sortable: false, label: 'Role' },
                { key: 'actions', sortable: false },
            ],
            filters: {
                email: { field: 'email', type: 'string', value: null, label: 'Email' },
                firstName: { field: 'firstName', type: 'string', value: null, label: 'First Name' },
                lastName: { field: 'lastName', type: 'string', value: null, label: 'Last Name' },
                admin: { field: 'admin', type: 'boolean', value: null, label: 'Admin' },
            },
        }
    },
    computed: {
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        reload() {
            this.$api.getAllItems(this, 'users')
                .then(users => {
                    this.items = users
                    if (this.$refs.locTable) {
                        this.$refs.locTable.refresh()
                    }
                })
        },
        navigate(route) {
            this.$router.push(route)
        },
        mask (user) {
            this.$store.state.maskUser = user
            sessionStorage.setItem('maskUser', user.id)
            this.$toasted.success('Currently masking as ' + user.email)
            this.$router.push('/')
        },
        async getItems (listParams) {
            // for simplicity just do the filtering locally
            let users = _.sortBy(this.items, [listParams.sortBy, 'lastName'])
            if (listParams.sortDesc) {
                users = _.reverse(users)
            }
            const email = _.trim(this.filters.email.value)
            if (email) {
                users = _.filter(users, user => {
                    return user.email && user.email.includes(firstName)
                })
            }
            const firstName = _.trim(this.filters.firstName.value)
            if (firstName) {
                users = _.filter(users, user => {
                    return user.firstName && user.firstName.includes(firstName)
                })
            }
            const lastName = _.trim(this.filters.lastName.value)
            if (lastName) {
                users = _.filter(users, user => {
                    return user.lastName && user.lastName.includes(lastName)
                })
            }
            const admin = this.filters.admin.value
            if (admin === true) {
                users = _.filter(users, user => {
                    return user.admin
                })
            } else if (admin === false) {
                users = _.filter(users, user => {
                    return !user.admin
                })
            }
            return users
        },
        showRole(item) {
            return item.admin ? 'Admin' : ''
        },
        async deleteUser(user) {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('USER_DELETE_CONFIRM'), {okVariant: 'danger', okTitle: this.$i18n.gt('DELETE_TOOLTIP')})) {
                await this.$api.deleteItem(this, 'users', user)
                this.$toasted.success(this.$i18n.gt('USER_DELETE_SUCCESS'))
                this.reload()
            }
        }
    },
    mounted() {
        this.reload()
    }
}
</script>

<style lang="scss">
.user-manager {
}
</style>
